import React, {useState,useEffect} from 'react'
import {Helmet} from "react-helmet";
import { useLoadingContext } from "react-router-loading";

import invest from '../img/invest.jpg'
import cyprus from '../img/cyprus.jpg'
import scroll from'../img/scroll.svg'
import thead1 from'../img/thead1.svg'
import thead2 from'../img/thead2.svg'
import thead3 from'../img/thead3.svg'
import arrdown from'../img/arrdown.svg'
import '../styles/Investments.scss';
import getFetch from '../components/functions/fetching';

const Investments = () => {
  const loadingContext = useLoadingContext();
  
  // useEffect(() => {
  //   loading()
  // }, [])
  
  // const loading = async () => {
    // loading some data

    // call method to indicate that loading is done and we are ready to switch
  //   loadingContext.done();
  // };

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const fethUrl = "https://apbbuild-project.com/app/api/investments-investments?fields=title,description,subTitle,subDescription&populate[columnPicture][fields]=url,alternativeText&populate[card][fields]&populate[row][fields]&populate[rowHeader][populate][headerColumn][populate][icon][fields]=url,alternativeText&populate[backGround][fields]=url,name&populate[button][populate][icon][fields]=url,alternativeText"
  
  useEffect(() => {
    getFetch(fethUrl,error,setError,isLoaded,setIsLoaded,items,setItems)
  }, [])
  
  
  if (error) {
    return <div>Ошибка: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Загрузка...</div>;
  } else {
  return (
    <div className='investments'>
      <Helmet>
          <title>Investments</title>
      </Helmet>



      <section className='investments__container' >
      <div className='investments__bg'>
          <img src={"https://apbbuild-project.com/app"+items.data.attributes.backGround.data.attributes.url} alt="invest" onLoad={loadingContext.done}/>

          <div className='scroll-me'>
          <div className='scroll-me__img'>
            <img src={scroll} alt="scroll" />
          </div>
          
          <p>Scroll down</p>
        </div>
      </div>

        <div className='investments__columns'>
          <div className='investments__column'>
          <div className='investments__title'>
          <h2>
            {
              items.data.attributes.title
                }
          </h2>

          <p>
            {
              items.data.attributes.description
            }
          </p>
        </div>
        <div className='investments__info investments-info'>

            {
              items.data.attributes.card.map(card=>(

                

                <div key={card.id} className='investments-info__card'>
            <div className='investments-info__bg'></div>
              <div className='investments-info__title'>
              {card.title}
              </div>
              <div className='investments-info__text'>
              {card.description}
              </div>
              
            </div>
              ))
            }

        </div>

          </div>


          <div className='investments__column'>
          
            <div className='investments__column-img-container'>
              <img src={"https://apbbuild-project.com/app"+items.data.attributes.columnPicture.data.attributes.url} alt="cyprus" />
            </div>  

          </div>

        </div>
        
        
      </section>


      <section className='investments__options investments-options'>
        <div className='investments-options__title'>
          <h2>{items.data.attributes.subTitle}</h2>
        </div>

        <div className='investments-options__main-info'>
        <div className='investments-options__main-title'>
            {items.data.attributes.row[0].text1}
            
            <p>{items.data.attributes.row[0].text2}</p>
        </div>
        <div className='investments-options__main-text'>
          {
            items.data.attributes.subDescription.split('\n').map(item=>(
              <p key={item}>
                {item}
              </p>
            ))
          }
        </div>

        </div>



        <div className='investments-options__options only-options'>
          <table className='only-options__table'>

          <thead className='only-options__thead'>
            <tr className='only-options__thead-row'>
              <th className='th-options-empty'>
                empty
              </th>
              {items.data.attributes.rowHeader.headerColumn.map(item=>(
                      <th key={item.id}>
                                      
                      <img src={"https://apbbuild-project.com/app"+item.icon.data.attributes.url}  alt="th1" />
                      <div className='only-options__th-title'>
                        {item.text}
                      </div>
                      
                      </th>

              ))}
              
            </tr>
          </thead>
          <tbody>

            {
              items.data.attributes.row.map(item=>(
                <tr className='only-options__row' key={item.id}>
                <td className='only-options__column column-title'>{item.text1}</td>
                
                <td className='only-options__column'>{item.text2}</td>
                <td className='only-options__column'>{item.text3}</td>
                <td className='only-options__column'>{item.text4}</td>
              </tr>
              ))
            }
            
            </tbody>
          </table>

          <div className='only-options__btn'>
          <p>{items.data.attributes.button.text}</p>
          <img src={"https://apbbuild-project.com/app"+items.data.attributes.button.icon.data.attributes.url} alt="arr" />
        </div>
        </div>


        
      </section>


      <section className='options-mobile'>
            
        <div className='options-mobile__card'>
        
        <table className='only-options__table'>

          <thead className='only-options__thead'>
            <tr className='only-options__thead-row'>
              
              <th>
                
                <img src={"https://apbbuild-project.com/app"+items.data.attributes.rowHeader.headerColumn[0].icon.data.attributes.url} alt="th1" />
                <div className='only-options__th-title investor-title'>
                {items.data.attributes.rowHeader.headerColumn[0].text}
                </div>
                
              </th>
              
            </tr>
          </thead>
          <tbody>


          {
              items.data.attributes.row.map(item=>(
                <tr className='only-options__row' key={item.id}>
                <td className='only-options__column column-title'>{item.text1}</td>
                
                <td className='only-options__column'>{item.text2}</td>
              </tr>
              ))
            }
            
            </tbody>
          </table>
          <div className='only-options__btn'>
          <p>{items.data.attributes.button.text}</p>
          <img src={"https://apbbuild-project.com/app"+items.data.attributes.button.icon.data.attributes.url} alt="arr" />
        </div>
        </div>

        <div className='options-mobile__card'>
        <table className='only-options__table'>

          <thead className='only-options__thead'>
            <tr className='only-options__thead-row'>
              
              <th>
                
                <img src={"https://apbbuild-project.com/app"+items.data.attributes.rowHeader.headerColumn[1].icon.data.attributes.url} alt="th2" />
                <div className='only-options__th-title'>
                {items.data.attributes.rowHeader.headerColumn[1].text}
                </div>
                
              </th>
              
            </tr>
          </thead>
          <tbody>

          {
              items.data.attributes.row.map(item=>(
                <tr className='only-options__row' key={item.id}>
                <td className='only-options__column column-title'>{item.text1}</td>
                
                <td className='only-options__column'>{item.text3}</td>
              </tr>
              ))
            }
            
            </tbody>
          </table>
          <div className='only-options__btn'>
          <p>{items.data.attributes.button.text}</p>
          <img src={"https://apbbuild-project.com/app"+items.data.attributes.button.icon.data.attributes.url} alt="arr" />
        </div>
        </div>

        <div className='options-mobile__card'>
        <table className='only-options__table'>

          <thead className='only-options__thead'>
            <tr className='only-options__thead-row'>
              
              <th>
                
                <img src={"https://apbbuild-project.com/app"+items.data.attributes.rowHeader.headerColumn[2].icon.data.attributes.url} alt="th3" />
                <div className='only-options__th-title'>
                {items.data.attributes.rowHeader.headerColumn[2].text}
                </div>
                
              </th>
              
            </tr>
          </thead>
          <tbody>


          {
              items.data.attributes.row.map(item=>(
                <tr className='only-options__row' key={item.id}>
                <td className='only-options__column column-title'>{item.text1}</td>
                
                <td className='only-options__column'>{item.text4}</td>
              </tr>
              ))
            }
            
            </tbody>
          </table>
          <div className='only-options__btn'>
          <p>{items.data.attributes.button.text}</p>
          <img src={"https://apbbuild-project.com/app"+items.data.attributes.button.icon.data.attributes.url} alt="arr" />
        </div>
        </div>
      </section>

    </div>
  )
}
}
export default Investments
import React, {useEffect,useState} from 'react'
import {Helmet} from "react-helmet";
import about from '../img/about.jpg'
import '../styles/About.scss';
import { useLoadingContext } from "react-router-loading";
import getFetch from '../components/functions/fetching';

const About = () => {

  const loadingContext = useLoadingContext();
  window.scrollTo(0, 0);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const fethUrl = "https://apbbuild-project.com/app/api/about-the-company?populate[backGround][fields]=url,alternativeText&fields=title,description"
  
  useEffect(() => {
    getFetch(fethUrl,error,setError,isLoaded,setIsLoaded,items,setItems)
  }, [])
  
  if (error) {
    return <div>Ошибка: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Загрузка...</div>;
  } else {
  return (

    
    <div className='about'>
      <Helmet>
          <title>About</title>
      </Helmet>
      



      <section className='about__container'>
      <div className='about__bg'>
          <img src={"https://apbbuild-project.com/app"+items.data.attributes.backGround.data.attributes.url} alt='about' onLoad={loadingContext.done}/>
      </div>
        <div className='about__title'>
        {
        items.data.attributes.title
          }
        </div>
        <div className='about__text'>

          {
            items.data.attributes.description.split('\n').map(item=>(
              <p key={item}>{item}</p>
            ))
          }
          
        </div>
      </section>

    </div>
  )
  }
}

export default About
import React,{useEffect,useState} from 'react'
import {Helmet} from "react-helmet";
import contacts from '../img/contacts.jpg'
import { useLoadingContext } from "react-router-loading";
import '../styles/Contacts.scss';
import getFetch from '../components/functions/fetching';
const Contacts = () => {
  const loadingContext = useLoadingContext();

  // useEffect(() => {
  //   loading()
  // }, [])
  
  // const loading = async () => {
    // loading some data

    // call method to indicate that loading is done and we are ready to switch
  //   loadingContext.done();
  // };

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const fethUrl = "https://apbbuild-project.com/app/api/contacts-contacts?fields=title,description,mapLink&populate[backGround][fields]=url,alternativeText&populate[card][fields]"
  
  useEffect(() => {
    
    getFetch(fethUrl,error,setError,isLoaded,setIsLoaded,items,setItems)
  }, [])
  if (error) {
    return <div>Ошибка: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Загрузка...</div>;
  } else {
  
  return (
    <div className='contacts'>
      <Helmet>
          <title>Contacts</title>
      </Helmet>
     
      <section className='contacts__container'>
      <div className='contacts__bg'>
          <img src={"https://apbbuild-project.com/app"+items.data.attributes.backGround.data.attributes.url} alt="contacts" onLoad={loadingContext.done}/>
      </div>
        <div className='contacts__body'>
          <div className='contacts__title'>
            <h2>{items.data.attributes.title}</h2>

          </div>
          <div className='contacts__text'>
          {items.data.attributes.description}

          </div>
          <div className='contacts__map'>
          <div className="lds-ripple"><div></div><div></div></div>
          <iframe src={items.data.attributes.mapLink}/>

          </div>
          <div className='contacts__bottom'>
            <div className='contacts__phone'>
            <a href={"tel:" + items.data.attributes.card[0].link}>{items.data.attributes.card[0].text}</a>
            </div>
            <div className='contacts__adress'>
            
            {
              items.data.attributes.card[1].text
            }
            </div>
            <div className='contacts__mail'>
            <a href={"mailto:" + items.data.attributes.card[2].link}>{items.data.attributes.card[2].text}</a>
            </div>

          </div>

        </div>

      </section>
      
      
    </div>
  )
}
}
export default Contacts
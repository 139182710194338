import React, {useEffect,useState} from 'react'
import {Helmet} from "react-helmet";
import { useLoadingContext } from "react-router-loading";

import pr1 from '../img/pr1.jpg'
import pr2 from '../img/pr2.jpg'
import pr3 from '../img/pr3.jpg'
import flower from '../img/flower.svg'
import sea from '../img/sea.svg'
import metr from '../img/metr.svg'
import arrdown from '../img/arrdown.svg'
import '../styles/Projects.scss';
import getFetch from '../components/functions/fetching';
const Projects = () => {
  const loadingContext = useLoadingContext();

  // useEffect(() => {
  //   loading()
  // }, [])
  // const loading = async () => {
    // loading some data

    // call method to indicate that loading is done and we are ready to switch
//     loadingContext.done();
// };
const [error, setError] = useState(null);
const [isLoaded, setIsLoaded] = useState(false);
const [items, setItems] = useState([]);
const fethUrl = "https://apbbuild-project.com/app/api/projects-projects?populate[project][populate][info][populate][icon][fields]=url,alternativeText&populate[project][populate][button][populate][icon][fields]=url,alternativeText&populate[project][populate][backGround][fields]=url,alternativeText&populate[leftbigcolumn][populate][info][populate][icon][fields]=url,alternativeText&populate[leftbigcolumn][populate][button][populate][icon][fields]=url,alternativeText&populate[leftbigcolumn][populate][backGround][fields]=url,alternativeText"
  
useEffect(() => {
  
  getFetch(fethUrl,error,setError,isLoaded,setIsLoaded,items,setItems)
}, [])
if (error) {
  return <div>Ошибка: {error.message}</div>;
} else if (!isLoaded) {
  return <div>Загрузка...</div>;
} else {
  return (
    <div className='projects' style={{minHeight:window.innerHeight}}>
<Helmet>
          <title>Projects</title>
      </Helmet>

      <div className='projects__container'>
          
          <div className='projects__row projects-row-comp'>


            <div className='left'>
              
              {
                items.data.attributes.leftbigcolumn.map(item=>(
                  <div key={item.id} className='projects__column main-column'>
                    <div className='main-column__card'>
                      <img src={"https://apbbuild-project.com/app"+item.backGround.data.attributes.url} alt={item.backGround.data.attributes.alternativeText} onLoad={loadingContext.done}/>
                      <div className='card-title'>
                        {item.title}
                      </div>
                      <div className='card-info'>
                      {
                        item.info.map(info=>(
                          
                        <div key={info.id} className='card-info__name'>
                          <img src={"https://apbbuild-project.com/app"+info.icon.data.attributes.url} alt={info.icon.data.attributes.alternativeText} />
                          <p>{info.text}</p>
                        </div>
                     
                        ))
                      }
                        </div> 
                      <div className='details'>
                        <p>{item.button.text}</p>
                        <img src={"https://apbbuild-project.com/app"+item.button.icon.data.attributes.url} alt={item.button.icon.data.attributes.alternativeText} />
                      </div>
                    </div>
                                  
                  </div>  
                ))
              }

              
            </div>
            <div className='right'>

             
            <div className='projects__column sub-column'>

                {
                  items.data.attributes.project.map(item=>(
                <div key={item.id} className='sub-column__card'>
                  <img src={"https://apbbuild-project.com/app"+item.backGround.data.attributes.url} alt={item.backGround.data.attributes.alternativeText} />
                  <div className='card-title'>
                    {item.title}
                  </div>
                  <div className='card-info'>
                    {
                        item.info.map(info=>(
                          
                        <div key={info.id} className='card-info__name'>
                          <img src={"https://apbbuild-project.com/app"+info.icon.data.attributes.url} alt={info.icon.data.attributes.alternativeText} />
                          <p>{info.text}</p>
                        </div>
                        ))
                      }
                  </div>  
                  <div className='details'>
                        <p>{item.button.text}</p>
                        <img src={"https://apbbuild-project.com/app"+item.button.icon.data.attributes.url} alt={item.button.icon.data.attributes.alternativeText} />
                      </div>
                </div>
                  ))
                }
                
                
                
                
                  </div>
              
              
            </div>
            
            
          </div>
          
      </div>

    </div>
  )
}
}
export default Projects
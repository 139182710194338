import './App.css';
import React,{useState,useEffect} from 'react'
import { Routes, Route } from "react-router-loading";
import Header from './components/Header';
import About from './pages/About';
import Projects from './pages/Projects';
import Services from './pages/Services';
import Investments from './pages/Investments';
import Contacts from './pages/Contacts';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';

function App() {

  return (
    <>
    <Header/>
    
    <Routes>
        <Route path="/" element={<About/>} loading/>
        <Route path="projects" element={<Projects />} loading />
        <Route path="services" element={<Services />} loading />
        <Route path="investments" element={<Investments/>} loading/>
        <Route path="contacts" element={<Contacts/>} loading/>
    </Routes>
    <ScrollToTop />


    {/* <Footer/> */}

    
    </>
  );

  
}

export default App;

import React from 'react'
import { useState,useEffect} from 'react'
import { Routes, Route, Link } from "react-router-dom";
import '../styles/Header.scss';
import header from '../img/header.svg'
import burger from '../img/burger.svg'
import exit from '../img/exit.svg'
import getFetch from './functions/fetching';
const Header = () => {

  let [link,setLink] = useState(window.location.pathname.replace('/',''))
  //link=window.location.pathname.replace('/','')
  
  const[isActive,setIsActive]=useState(false)


  
  const burgerClick=()=>{
    setIsActive(prev=>!prev)

  }

  const linkClick=(e)=>{

    if(e.target.textContent==''){
      e.target.textContent='about the company'
    }
    setLink(e.target.textContent)

    
  }

  // useEffect(()=>{
  //   let btns=document.querySelectorAll('.header-main__link')



  //   btns.forEach(btn=>{
  //     btn.addEventListener('click',()=>{
  //       handleClick(btn)
  //     })
  //   })



 



  // })
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const fethUrl = "https://apbbuild-project.com/app/api/header?populate[nav][fields]&populate[logo][fields]&populate[logo][populate][logo][fields]=url,alternativeText&populate[contacts][fields]"
  
  useEffect(() => {
    
    getFetch(fethUrl,error,setError,isLoaded,setIsLoaded,items,setItems)
  }, [])
  if (error) {
    return <div>Ошибка: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Загрузка...</div>;
  } else {
  return (
    <>

    <div className='header'>
      <div  className='header__container'>
        <section className='header__contacts header-contacts'>
          <div className='header-contacts__phone'>
            <a href={"tel:" + items.data.attributes.contacts[0].text}>{items.data.attributes.contacts[0].text}</a>
          </div>
          <div className='header-contacts__mail'>
            <a href={"mailto:" + items.data.attributes.contacts[1].text}>{items.data.attributes.contacts[1].text}</a>
          </div>
        </section>
      



        <section  className='header__main header-main'>
          <div  className='header-main__container'>
            <div  className='header-main__logo'>
              <Link onClick={(e)=>linkClick(e)} to='/'><img src={"https://apbbuild-project.com/app"+items.data.attributes.logo.logo.data.attributes.url} alt='logo' /></Link>
              
            </div>  

            <div className='header-main__right-side-comp'>
              <div  className='header-main__links'>
                {
                  items.data.attributes.nav.map(item=>(
                    <Link key={item.id} onClick={(e)=>linkClick(e)} className={link==item.text || (link=='' &&  item.text == items.data.attributes.nav[0].text) ? 'header-main__link active' : 'header-main__link'} to={item.link}>{item.text}</Link>
                  ))
                }
              </div>  
              <div  className='header-main__form form-btn-container'>
                <div className='form-btn-container__btn'>
                  {items.data.attributes.button}
                </div>
              </div>     
            </div>

            <div onClick={burgerClick} className='right-side-mob-burger'>
                <img src={burger} alt="burger" />
            </div>
            <div className={isActive ? 'header-main__right-side-mob right-side-mob active' : 'header-main__right-side-mob right-side-mob noactive'}>
              
              <div onClick={burgerClick} className='right-side-mob__exit'>
                <img src={exit} alt="exit" />
              </div>
              <div  className='header-main__links'>
                {
                  items.data.attributes.nav.map(item=>(
                    <Link onClick={burgerClick} key={item.id} className="header-main__link" to={item.link}>{item.text}</Link>
                  ))
                }
                
              </div>  
              <div  className='header-main__form form-btn-container'>
                <div className='form-btn-container__btn'>
                {items.data.attributes.button}
                </div>
              </div>     
            </div>
          </div>
          
        </section>
        
      </div>

      

    </div>



    
    </>
  )
}
}
export default Header
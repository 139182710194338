import React, {useEffect,useState} from 'react'
import {Helmet} from "react-helmet";
import services from '../img/services.jpg'

import dev1 from '../img/dev1.svg'
import dev2 from '../img/dev2.svg'
import dev3 from '../img/dev3.svg'
import dev4 from '../img/dev4.svg'
import dev5 from '../img/dev5.svg'
import dev6 from '../img/dev6.svg'
import getFetch from '../components/functions/fetching';

import '../styles/Services.scss';
import { useLoadingContext } from "react-router-loading";



const Services = () => {
  const loadingContext = useLoadingContext();

  // useEffect(() => {
  //   loading()
  // }, [])
  
  // const loading = async () => {
    // loading some data

    // call method to indicate that loading is done and we are ready to switch
  //   loadingContext.done();
  // };
  const [error, setError] = useState(null);
const [isLoaded, setIsLoaded] = useState(false);
const [items, setItems] = useState([]);
const fethUrl = "https://apbbuild-project.com/app/api/services-services?fields=title&populate[service][populate][info][populate][icon][fields]=url,alternativeText&populate[servicesbottom][populate][info][populate][icon][fields]=url,alternativeText&populate[backGround][fields]=url,alternativeText"
  
useEffect(() => {
  
  getFetch(fethUrl,error,setError,isLoaded,setIsLoaded,items,setItems)
}, [])
if (error) {
  return <div>Ошибка: {error.message}</div>;
} else if (!isLoaded) {
  return <div>Загрузка...</div>;
} else {
  return (
    <>
    <div className='services'>
    <Helmet>
          <title>Services</title>
      </Helmet>
      



      <section className='services__container'>
      <div className='services__bg'>
          <img src={"https://apbbuild-project.com/app"+items.data.attributes.backGround.data.attributes.url} alt="services" onLoad={loadingContext.done}/>
      </div>
        <div className='services__body'>
        <div className='services__title'>
          <h2>{items.data.attributes.title}</h2>
        </div>

        {
          items.data.attributes.service.map(service=>(
            <div key={service.id} className='services__development service-development'>
                {
                  service.info.map(item=>(
                    <div key={item.id} className='service-development__card'>
                  <div className='service-development__bg'></div>
                    <div className='service-development__logo'>
                      <img src={"https://apbbuild-project.com/app"+item.icon.data.attributes.url} alt={item.icon.data.attributes.alternativeText}/>
                      </div>
                    <div className='service-development__title'>
                    {item.text}
                    </div>
                    
                  </div>
                  ))
                }

            </div>
            ))
        
          
        }
            
            {
          items.data.attributes.servicesbottom.map(service=>(
            <div key={service.id} className='services__bottom service-bottom'>
                {
                  service.info.map(item=>(
                    <div key={item.id} className='service-bottom__card'>
                  <div className='service-bottom__bg'></div>
                    <div className='service-bottom__logo'>
                      <img src={"https://apbbuild-project.com/app"+item.icon.data.attributes.url} alt={item.icon.data.attributes.alternativeText}/>
                      </div>
                    <div className='service-bottom__title'>
                    {item.text}
                    </div>
                    
                  </div>
                  ))
                }

            </div>
            ))
        
          
        }
        
        </div>

        
      </section>

    </div>
    </>
  )
  
}
}
export default Services